$(document).ready(function () {
  $("a[rel=popover]").popover();
  $(".tooltip").tooltip();
  $("a[rel=tooltip]").tooltip();

  // Lock out ethnicity choices when hispanic is selected
  $('#user_form').on('click', '.hispanic_radio_button', function () {
    var value = $(this).prop('value')

    if (value == 'true') {
      $('.ethnicity').children('label').children('input').prop('disabled', 'disabled')
      $('.ethnicity').children('label').children('input').prop('checked', null)
      $('.other_ethnicity').hide()
    }

    if (value == 'false') {
      $('.ethnicity').children('label').children('input').prop('disabled', null)
      $('.other_ethnicity').show()
    }
  });

  $(document).on("click", "input[type='radio']", function(){
    thisRadio = $(this);
    if (thisRadio.hasClass("imChecked")) {
      thisRadio.removeClass("imChecked");
      thisRadio.prop('checked', false);
    } else {
      thisRadio.prop('checked', true);
      thisRadio.addClass("imChecked");
    };
  })

});
